import { call, put, takeEvery } from 'redux-saga/effects'
import { aboutConstants } from '../constants/about.constants';
import { about_api, addForm_api, bodiesOfChember_api, deleteBodiesChember_api, editAbout_api, freeInfo_api } from '../api/about.api';
import { getAboutContent_failure, getAboutContent_success, editAboutContent_failure, editAboutContent_success, getBodiesChember_failure, getBodiesChember_success, getFreeInfo_failure, getFreeInfo_success, addForm_failure, addForm_success, deleteBodiesChember_failure, deleteBodiesChember_success, deleteFreeInfo_failure, deleteFreeInfo_success } from '../actions/aboute.action';


export function* getContent() {
    const response = yield call(about_api)
    if(!response || !response.data) {
        return yield put(getAboutContent_failure('Internal server error for geting about content'))
    }
    if(response.status === 200) {   
        return yield put(getAboutContent_success(response.data))
    } else {
        return yield put(getAboutContent_failure('Eror for geting about content'))
    }
}

export function* editContent(action) {
    const response = yield call(editAbout_api, action.payload)
    if(!response || !response.data) {
        // return yield put(getAboutContent_failure('Internal server error for geting about content'))
        return yield put(editAboutContent_failure('Internal server error for geting about content'))
    }
    if(response.status === 200) {   
        // return yield put(getAboutContent_success(response.data))
        return yield put(editAboutContent_success(response.data))
    } else {
        // return yield put(getAboutContent_failure('Eror for geting about content'))
        return yield put(editAboutContent_failure('Eror for geting about content'))
    }
}

//
//novi api-s za organe komore i pristum slobodnim info
//
//get bodies of chember news

export function* getBodiesChember() {
    const response = yield call(bodiesOfChember_api)
    if(!response || !response.data) {

        return yield put(getBodiesChember_failure('Internal server error for geting about content'))
    }
    if(response.status === 200) {   
        return yield put(getBodiesChember_success(response.data))
    } else {
        return yield put(getBodiesChember_failure('Eror for geting about content'))
    }
}
//delete
export function* deleteBodiesChember(action) {
    const response = yield call(deleteBodiesChember_api, action.payload.id)
    if(!response || !response.data) {
        return yield put(deleteBodiesChember_failure('Internal server error for delete bodies chember'))
    }
    if(response.status === 200) {
        return yield put(deleteBodiesChember_success(response.data))
    } else {
        return yield put(deleteBodiesChember_failure('Error for delete news'))
    }
}
export function* deleteFreeInfo(action) {
    const response = yield call(deleteBodiesChember_api, action.payload.id)
    if(!response || !response.data) {
        return yield put(deleteFreeInfo_failure('Internal server error for delete free info'))
    }
    if(response.status === 200) {
        return yield put(deleteFreeInfo_success(response.data))
    } else {
        return yield put(deleteFreeInfo_failure('Error for delete news'))
    }
}
//get free info
export function* getFreeInfo() {
    const response = yield call(freeInfo_api)
    if(!response || !response.data) {
        return yield put(getFreeInfo_failure('Internal server error for geting about content'))
    }
    if(response.status === 200) {   
        return yield put(getFreeInfo_success(response.data))
    } else {
        return yield put(getFreeInfo_failure('Eror for geting about content'))
    }
}

export function* addForm(action) {
    console.log('saga')
    const response = yield call(addForm_api, action.payload)
    if(!response || !response.data) {
        return yield put(addForm_failure('Internal server error for geting about adding form'))
    }
    if(response.status === 200) {   
        return yield put(addForm_success(response.data))
    } else {
        return yield put(addForm_failure('Eror for geting about adding form'))
    }
}


export function* aboutSaga() {
    yield takeEvery(aboutConstants.GETABOUTCONTENT_REQUEST, getContent)
    yield takeEvery(aboutConstants.EDITABOUTCONTENT_REQUEST, editContent)
    yield takeEvery(aboutConstants.GETBODIESCHEMBER_REQUEST, getBodiesChember)
    yield takeEvery(aboutConstants.GETFREEINFO_REQUEST, getFreeInfo)
    yield takeEvery(aboutConstants.ADDFORM_REQUEST, addForm)
    yield takeEvery(aboutConstants.DELETEBODIESCHEMBER_REQUEST, deleteBodiesChember)
    yield takeEvery(aboutConstants.DELETEFREEINFO_REQUEST, deleteFreeInfo)
}