export const parliamentConstants = {
    GETPARLIAMENTPG_REQUEST: 'GET_PARLIAMENT_PG_REQUEST',
    GETPARLIAMENTPG_SUCCESS: 'GET_PARLIAMENT_PG_SUCCESS',
    GETPARLIAMENTPG_FAILURE: 'GET_PARLIAMENT_PG_FAILURE',
    EDITPARLIAMENTPG_REQUEST: 'EDIT_PARLIAMENT_PG_REQUEST',
    EDITPARLIAMENTPG_SUCCESS: 'EDIT_PARLIAMENT_PG_SUCCESS',
    EDITPARLIAMENTPG_FAILURE: 'EDIT_PARLIAMENT_PG_FAILURE',

    GETPARLIAMENTCT_REQUEST: 'GET_PARLIAMENTCT_REQUEST',
    GETPARLIAMENTCT_SUCCESS: 'GET_PARLIAMENTCT_SUCCESS',
    GETPARLIAMENTCT_FAILURE: 'GET_PARLIAMENTCT_FAILURE',
    EDITPARLIAMENTCT_REQUEST: 'EDIT_PARLIAMENT_CT_REQUEST',
    EDITPARLIAMENTCT_SUCCESS: 'EDIT_PARLIAMENT_CT_SUCCESS',
    EDITPARLIAMENTCT_FAILURE: 'EDIT_PARLIAMENT_CT_FAILURE',


    GETPARLIAMENTNK_REQUEST: 'GET_PARLIAMENT_NK_REQUEST',
    GETPARLIAMENTNK_SUCCESS: 'GET_PARLIAMENT_NK_SUCCESS',
    GETPARLIAMENTNK_FAILURE: 'GET_PARLIAMENT_NK_FAILURE',
    EDITPARLIAMENTNK_REQUEST: 'EDIT_PARLIAMENT_NK_REQUEST',
    EDITPARLIAMENTNK_SUCCESS: 'EDIT_PARLIAMENT_NK_SUCCESS',
    EDITPARLIAMENTNK_FAILURE: 'EDIT_PARLIAMENT_NK_FAILURE',



    GETPARLIAMENTSOUTH_REQUEST: 'GET_PARLIAMENT_SOUTH_REQUEST',
    GETPARLIAMENTSOUTH_SUCCESS: 'GET_PARLIAMENT_SOUTH_SUCCESS',
    GETPARLIAMENTSOUTH_FAILURE: 'GET_PARLIAMENT_SOUTH_FAILURE',
    EDITPARLIAMENTSOUTH_REQUEST: 'EDIT_PARLIAMENT_SOUTH_REQUEST',
    EDITPARLIAMENTSOUTH_SUCCESS: 'EDIT_PARLIAMENT_SOUTH_SUCCESS',
    EDITPARLIAMENTSOUTH_FAILURE: 'EDIT_PARLIAMENT_SOUTH_FAILURE',

    
    GETPARLIAMENTNORTH_REQUEST: 'GET_PARLIAMENT_NORTH_REQUEST',
    GETPARLIAMENTNORTH_SUCCESS: 'GET_PARLIAMENT_NORTH_SUCCESS',
    GETPARLIAMENTNORTH_FAILURE: 'GET_PARLIAMENT_NORTH_FAILURE',
    EDITPARLIAMENTNORTH_REQUEST: 'EDIT_PARLIAMENT_NORTH_REQUEST',
    EDITPARLIAMENTNORTH_SUCCESS: 'EDIT_PARLIAMENT_NORTH_SUCCESS',
    EDITPARLIAMENTNORTH_FAILURE: 'EDIT_PARLIAMENT_NORTH_FAILURE'
}