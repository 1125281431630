import React from 'react'
import { withRouter } from 'react-router-dom'
import Novelty from '../singleComponents/Novelty';
import Pagination from 'react-js-pagination'
import Select from 'react-select'
import ModalCmp from '../ModalCmp';
import Header from '../Header';
import Sidebar from '../Sidebar';
import FreeInfoSingle from '../singleComponents/FreeInfoSingle';

class FreeInfo extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            bodiesChember: [],
            data: [],
            sort: {
                column : null,
                direction: 'desc'
            },
            activePage: 1,
            newsPerPage: 10,
            numberOfPagButton: 5,
            totalNews: 0,
            searchField: false,
            temporaryFiltered: [],
            showDeleteModal: false,
            novelty: '',
            categories: '',
            showDeleteNoveltyModal: false
        }
    }

    setup = () => {
        this.props.getFreeInfo()
    }

    componentDidMount() {
        this.setup()
    }


    static getDerivedStateFromProps(nextProps, prevProps) {
        let _pagChemb, _bodiesChember, _totalChemb
        if(nextProps.freeInfo !== prevProps.freeInfo) {
            _bodiesChember = nextProps.freeInfo
            _pagChemb = nextProps.freeInfo.slice(prevProps.activePage*prevProps.newsPerPage - prevProps.newsPerPage,
                prevProps.activePage*prevProps.newsPerPage, [])
            _totalChemb = nextProps.freeInfo.length
        } else {
            _pagChemb = nextProps.freeInfo.slice(prevProps.activePage*prevProps.newsPerPage - prevProps.newsPerPage,
                prevProps.activePage*prevProps.newsPerPage, [])
            _totalChemb = nextProps.freeInfo.length
        }
        return {
            bodiesChember: _bodiesChember,
            data: _pagChemb,
            totalNews: _totalChemb,
        }
    }


    setActivePage = (currentPage) => {
        let pagNews = this.props.freeInfo
        this.setState({
            activePage: currentPage,
            data: pagNews.slice(currentPage*this.state.newsPerPage - this.state.newsPerPage,
                    currentPage*this.state.newsPerPage, [])
        })
    }


    deleteNoveltyClick = (_novelty) => {
        this.setState({
            showDeleteNoveltyModal: true,
            novelty: _novelty
        })
    }

    closeModal = () => {
        this.setState({
            showDeleteNoveltyModal: false
        })
    }

    deleteNovelty = (_novelty) => {
        this.props.deleteFreeInfo(_novelty)
        this.closeModal()
    }

    render() {
        return(
            <div>
                <Header />
                <div className="col-md-2">
                    <Sidebar />
                </div>

                <div className="col-md-10 mainContent">
                    <div className="box col-md-12">
                        <div className="box-header">
                            <h3 className="box-title">Pregled podataka - Organi komore</h3>
                        </div>
                        <div className="box-body">
                            <table id="example1" className="table table-bordered table-striped">
                                <thead>
                                    <tr >
                                        <th>
                                            <span className="fa fa-sort"></span>
                                            ID
                                        </th>
                                        <th>
                                            <span className="fa fa-sort"></span>
                                            Naslov
                                        </th>
                                        <th>
                                            <span className="fa fa-sort"></span>
                                            Sadržaj
                                        </th>
                                        <th>
                                            <span className="fa fa-sort"></span>
                                            Datum
                                        </th>
                                        <th>
                                            Fajl
                                        </th>
                                        <th>
                                            Akcije
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.data !== undefined ?
                                        this.state.data.map(novelty => {
                                            return <FreeInfoSingle 
                                                key={novelty.id}
                                                id={novelty.id}
                                                title={novelty.title}
                                                content={novelty.content}
                                                date={novelty.date}
                                                file={novelty.file}
                                                deleteNovelty={() => this.deleteNoveltyClick(novelty)}
                                            />
                                        })
                                        : <tr>
                                            <td></td>
                                        </tr>
                                    }
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th>ID</th>
                                        <th>Naslov</th>
                                        <th>Sadržaj</th>
                                        <th>Datum</th>
                                        <th>Fajl</th>
                                        <th>Akcije</th>
                                    </tr>
                                </tfoot>
                            </table> 
                        </div>

                        <Pagination 
                            activePage={this.state.activePage}
                            itemsCountPerPage={this.state.newsPerPage}
                            totalItemsCount={this.state.totalNews}
                            pageRangeDisplayed={this.state.numberOfPagButton}
                            onChange={this.setActivePage}
                        />

                        <ModalCmp 
                            showModal={this.state.showDeleteNoveltyModal}
                            shutDownModal={this.closeModal}
                            sureMessage="Da li ste sigurni?"
                            novelty={this.state.novelty || ""}
                            deleteNovelty={() => this.deleteNovelty(this.state.novelty)}
                            deleteMemberModal = {false}
                        />


                    </div>
                </div>
            </div>


        )
    }
}

export default withRouter(FreeInfo)