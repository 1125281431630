export const advertismentConstants = {
    GETADV_REQUEST: "GET_ADV_REQUEST",
    GETADV_SUCCCESS: "GET_ADV_SUCCESS",
    GETADV_FAILURE: "GET_ADV_FAILURE",

    ADDADV_REQUEST: "ADD_ADV_REQUEST",
    ADDADV_SUCCESS: "ADD_ADV_SUCCESS",
    ADDADV_FAILURE: "ADD_ADV_FAILURE",

    EDITADV_REQUEST: "EDIT_ADV_REQUEST",
    EDITADV_SUCCESS: "EDIT_ADV_SUCCESS",
    EDITADV_FAILURE: "EDIT_ADV_FAILURE",
    
    DELETEADV_REQUEST: 'DELETE_ADV_REQUEST',
    DELETEADV_SUCCESS: 'DELETE_ADV_SUCCESS',
    DELETEADV_FAILURE: 'DELETE_ADV_FAILURE',

    GETADVDETAILS_REQUEST: "GET_ADV_DETAILS_REQUEST",
    GETADVDETAILS_SUCCESS: "GET_ADV_DETAILS_SUCCESS",
    GETADVDETAILS_FAILURE: "GET_ADV_DETAILS_FAILURE"
}