import { aboutConstants } from "../constants/about.constants";

const initialState = {
    content: [],
    bodiesChember: [],
    freeInfo: [],
}


export default function aboutReducer(state = initialState, action) {
    switch(action.type) {
        case aboutConstants.GETABOUTCONTENT_REQUEST:
            return {
                ...state,
                loading: true
            }
        case aboutConstants.GETABOUTCONTENT_SUCCESS:
            return {
                ...state,
                loading: false,
                content: action.payload
            }
        case aboutConstants.GETABOUTCONTENT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case aboutConstants.EDITABOUTCONTENT_REQUEST:
            return {
                ...state,
                loading: true
            }
        case aboutConstants.EDITABOUTCONTENT_SUCCESS:
            return {
                ...state,
                loading: false,
                content: action.payload
            }
        case aboutConstants.EDITABOUTCONTENT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
            //
            //novi taskovi za organe komore, slobodne info..
            //
            case aboutConstants.GETBODIESCHEMBER_REQUEST:
                return {
                    ...state,
                    loading: true
                }
            case aboutConstants.GETBODIESCHEMBER_SUCCESS:
                return {
                    ...state,
                    loading: false,
                    bodiesChember: action.payload
                }
            case aboutConstants.GETBODIESCHEMBER_FAILURE:
                return {
                    ...state,
                    loading: false,
                    error: action.payload
                }
            case aboutConstants.DELETEBODIESCHEMBER_REQUEST:
                return {
                    ...state,
                    loading: true
                }
            case aboutConstants.DELETEBODIESCHEMBER_SUCCESS:
                return {
                    ...state,
                    loading: false,
                    bodiesChember: state.bodiesChember.filter(news => news.id !== action.payload.id)
                }
            case aboutConstants.DELETEBODIESCHEMBER_FAILURE:
                return {
                    ...state,
                    loading: false,
                    error: action.payload
                }

            case aboutConstants.DELETEFREEINFO_REQUEST:
                return {
                    ...state,
                    loading: true
                }
            case aboutConstants.DELETEFREEINFO_SUCCESS:
                return {
                    ...state,
                    loading: false,
                    freeInfo: state.freeInfo.filter(news => news.id !== action.payload.id)
                }
            case aboutConstants.DELETEFREEINFO_FAILURE:
                return {
                    ...state,
                    loading: false,
                    error: action.payload
                }
            case aboutConstants.GETFREEINFO_REQUEST:
                return {
                    ...state,
                    loading: true
                }
            case aboutConstants.GETFREEINFO_SUCCESS:
                return {
                    ...state,
                    loading: false,
                    freeInfo: action.payload
                }
            case aboutConstants.GETFREEINFO_FAILURE:
                return {
                    ...state,
                    loading: false,
                    error: action.payload
                }
            case aboutConstants.ADDFORM_REQUEST:
                console.log("ADDFORM_REQUEST")
            return {
                ...state,
                loading: true
            }
            case aboutConstants.ADDFORM_SUCCESS:
                console.log("ADDFORM_SUCCESS")
                return {
                    ...state,
                    loading: false,
                }
            case aboutConstants.ADDFORM_FAILURE:
                return {
                    ...state,
                    loading: false,
                    error: action.payload
                }
        default:
            return state
    }
}