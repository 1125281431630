import { aboutConstants } from "../constants/about.constants";

//get about content 
export function getAboutContent_request(content) {
    return {
        type: aboutConstants.GETABOUTCONTENT_REQUEST,
        payload: content
    }
}
export function getAboutContent_success(content) {
    return {
        type: aboutConstants.GETABOUTCONTENT_SUCCESS,
        payload: content
    }
}
export function getAboutContent_failure(error) {
    return {
        type: aboutConstants.GETABOUTCONTENT_REQUEST,
        payload: error
    }
}
//get about content 


//edit abount content
export function editAboutContent_request(content) {
    return {
        type: aboutConstants.EDITABOUTCONTENT_REQUEST,
        payload: content
    }
}
export function editAboutContent_success(content) {
    return {
        type: aboutConstants.EDITABOUTCONTENT_SUCCESS,
        payload: content
    }
}
export function editAboutContent_failure(error) {
    return {
        type: aboutConstants.EDITABOUTCONTENT_FAILURE,
        payload: error
    }
}
//edit abount content



//
//
//nove putanje za organe komore, slobodne info...
//
//
export function getBodiesChember_request(content) {
    return {
        type: aboutConstants.GETBODIESCHEMBER_REQUEST,
        payload: content
    }
}
export function getBodiesChember_success(content) {
    return {
        type: aboutConstants.GETBODIESCHEMBER_SUCCESS,
        payload: content
    }
}
export function getBodiesChember_failure(error) {
    return {
        type: aboutConstants.GETBODIESCHEMBER_FAILURE,
        payload: error
    }
}
//delete actions
export function deleteBodiesChember_request(content) {
    return {
        type: aboutConstants.DELETEBODIESCHEMBER_REQUEST,
        payload: content
    }
}
export function deleteBodiesChember_success(content) {
    return {
        type: aboutConstants.DELETEBODIESCHEMBER_SUCCESS,
        payload: content
    }
}
export function deleteBodiesChember_failure(error) {
    return {
        type: aboutConstants.DELETEBODIESCHEMBER_FAILURE,
        payload: error
    }
}
//
export function deleteFreeInfo_request(content) {
    return {
        type: aboutConstants.DELETEFREEINFO_REQUEST,
        payload: content
    }
}
export function deleteFreeInfo_success(content) {
    return {
        type: aboutConstants.DELETEFREEINFO_SUCCESS,
        payload: content
    }
}
export function deleteFreeInfo_failure(error) {
    return {
        type: aboutConstants.DELETEFREEINFO_FAILURE,
        payload: error
    }
}
//
export function getFreeInfo_request(content) {
    return {
        type: aboutConstants.GETFREEINFO_REQUEST,
        payload: content
    }
}
export function getFreeInfo_success(content) {
    return {
        type: aboutConstants.GETFREEINFO_SUCCESS,
        payload: content
    }
}
export function getFreeInfo_failure(error) {
    return {
        type: aboutConstants.GETFREEINFO_FAILURE,
        payload: error
    }
}
//
//
//
export function addForm_request(content) {
    return {
        type: aboutConstants.ADDFORM_REQUEST,
        payload: content
    }
}
export function addForm_success(content) {
    return {
        type: aboutConstants.ADDFORM_SUCCESS,
        payload: content
    }
}
export function addForm_failure(error) {
    return {
        type: aboutConstants.ADDFORM_FAILURE,
        payload: error
    }
}