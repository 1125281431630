export const aboutConstants = {
   GETABOUTCONTENT_REQUEST: 'GET_ABOUT_CONTENT_REQUEST',
   GETABOUTCONTENT_SUCCESS: 'GET_ABOUT_CONTENT_SUCCESS',
   GETABOUTCONTENT_FAILURE: 'GET_ABOUT_CONTENT_FAILURE',

   EDITABOUTCONTENT_REQUEST: 'EDIT_ABOUT_CONTENT_REQUEST',
   EDITABOUTCONTENT_SUCCESS: 'EDIT_ABOUT_CONTENT_SUCCESS',
   EDITABOUTCONTENT_FAILURE: 'EDIT_ABOUT_CONTENT_FAILURE',

   GETBODIESCHEMBER_REQUEST: "GET_BODIES_CHEMBER_REQUEST",
   GETBODIESCHEMBER_SUCCESS: "GET_BODIES_CHEMBER_SUCCESS",
   GETBODIESCHEMBER_FAILURE: "GET_BODIES_CHEMBER_FAILURE",

   DELETEBODIESCHEMBER_REQUEST: "DELETE_BODIES_CHEMBER_REQUEST",
   DELETEBODIESCHEMBER_SUCCESS: "DELETE_BODIES_CHEMBER_SUCCESS",
   DELETEBODIESCHEMBER_FAILURE: "DELETE_BODIES_CHEMBER_FAILURE",

   GETFREEINFO_REQUEST: "GET_FREE_INFO_REQUEST",
   GETFREEINFO_SUCCESS: "GET_FREE_INFO_SUCCESS",
   GETFREEINFO_FAILURE: "GET_FREE_INFO_FAILURE",

   DELETEFREEINFO_REQUEST: "DELETE_FREE_INFO_REQUEST",
   DELETEFREEINFO_SUCCESS: "DELETE_FREE_INFO_SUCCESS",
   DELETEFREEINFO_FAILURE: "DELETE_FREE_INFO_FAILURE",

   ADDFORM_REQUEST: "ADD_FORM_REQUEST",
   ADDFORM_SUCCESS: "ADD_FORM_SUCCESS",
   ADDFORM_FAILURE: "ADD_FORM_FAILURE"
}