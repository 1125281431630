export const memberConstants = {
    GETMEMBERS_REQUEST: 'GET_MEMBERS_REQUEST',
    GETMEMBERS_SUCCESS: 'GET_MEMBERS_SUCCESS',
    GETMEMBERS_FAILURE: 'GET_MEMBERS_FAILURE',

    ADDMEMBER_REQUEST: 'ADD_MEMBER_REQUEST',
    ADDMEMBER_SUCCESS: 'ADD_MEMBRE_SUCCESS',
    ADDMEMBER_FAILURE: 'ADD_MEMBER_FAILURE',

    EDITMEMBER_REQUEST: 'EDIT_MEMBER_REQUEST',
    EDITMEMBER_SUCCESS: 'EDIT_MEMBER_SUCCESS',
    EDITMEMBER_FAILURE: 'EDIT_MEMBER_FAILURE',

    DELETEMEMBER_REQUEST: 'DELETE_MEMBER_REQUEST',
    DELETEMEMBER_SUCCESS: 'DELETE_MEMBER_SUCCESS',
    DELETEMEMBER_FAILURE: 'DELETE_MEMBER_FAILURE',

    GETBOARDMEMBERS_REQUEST: 'GET_BOARD_MEMBERS_REQUEST',
    GETBOARDMEMBERS_SUCCESS: 'GET_BOARD_MEMBERS_SUCCESS',
    GETBOARDMEMBERS_FAILURE: 'GET_BOARD_MEMBERS_FAILURE',

    BOARDMEMBERADD_REQUEST: 'BOARD_MEMBER_ADD_REQUEST',
    BOARDMEMBERADD_SUCCESS: 'BOARD_MEMBER_ADD_SUCCESS',
    BOARDMEMBERADD_FAILURE: 'BOARD_MEMBER_ADD_FAILURE',

    BOARDMEMBERDELETE_REQUEST: 'BOARD_MEMBER_DELETE_REQUEST',
    BOARDMEMBERDELETE_SUCCESS: 'BOARD_MEMBER_DELETE_SUCCESS',
    BOARDMEMBERDELETE_FAILURE: 'BOARD_MEMBER_DELETE_FAILURE',

    GETMEMBERDETAILS_REQUEST: 'GET_MEMBER_DETAILS_REQUEST',
    GETMEMBERDETAILS_SUCCESS: 'GET_MEMBER_DETAILS_SUCCESS',
    GETMEMBERDETAILS_FAILURE: 'GET_MEMBER_DETAILS_FAILURE',
    
    GETBOARDMEMBERDETAILS_REQUEST: 'GET_BOARD_MEMBER_DETAILS_REQUEST',
    GETBOARDMEMBERDETAILS_SUCCESS: 'GET_BOARD_MEMBER_DETAILS_SUCCESS',
    GETBOARDMEMBERDETAILS_FAILURE: 'GET_BOARD_MEMBER_DETAILS_FAILURE',

    GETCITIES_REQUEST: "GET_CITIES_REQUEST",
    GETCITIES_SUCCESS: "GET_CITIES_SUCCESS",
    GETCITIES_FAILURE: "GET_CITIES_FAILURE"
}