import { connect } from 'react-redux'
import FreeInfo from '../../components/aboutChember/FreeInfo';
import { deleteFreeInfo_request, getFreeInfo_request } from '../../actions/aboute.action';

const mapStateToProps = state => ({
    freeInfo: state.aboutReducer.freeInfo
})

const mapDispatchToProps = dispatch => ({
    getFreeInfo: () => dispatch(getFreeInfo_request()),
    deleteFreeInfo: (novelty) => dispatch(deleteFreeInfo_request(novelty))
})

const FreeInfoCnt = connect (
    mapStateToProps,
    mapDispatchToProps
)(FreeInfo)

export default FreeInfoCnt