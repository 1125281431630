import { connect } from 'react-redux'
import AddForm from '../../components/aboutChember/AddForm';
import { addForm_request } from '../../actions/aboute.action';

const mapStateToProps = state => ({
    addForm: state.aboutReducer.addForm
})

const mapDispatchToProps = dispatch => ({
    addFormDetails: (item) => dispatch(addForm_request(item)),
})

const AddFormCnt = connect(
    mapStateToProps,
    mapDispatchToProps
)(AddForm)

export default AddFormCnt