import React from 'react'
import { withRouter } from 'react-router-dom'
import Select from 'react-select'
import Header from '../Header';
import Sidebar from '../Sidebar';
import RichTextEditor from 'react-rte';
import ImageUploader from 'react-images-upload'

class AddForm extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            addForm: '',
            title: '',
            page: 0,
            content: '',
            full_text: RichTextEditor.createEmptyValue(),
            categories: [],
            errorName: '',
            errorCity: '',
            errorCompany: '',
            errorPhone: '',
            selectedCategory: '',
            images: [],
            titleImg: []
        }
    }
    
    static getDerivedStateFromProps(nextProps, prevProps) {
        if(nextProps.addForm !== prevProps.addForm) {
            return {
                addForm: nextProps.addForm
            }
        }
        return null
    }


    changeTitle = (input) => {
        this.setState({
            title: input.target.value
        })
    }

    changeContent = (input) => {
        this.setState({
            content: input.target.value
        })
    }

    changeFullText = (input) => {
          this.setState({
              full_text: input
          })
    }

    changePage = (input) => {
        this.setState({
            page: input
        })
  }

    resetFields = () => {
        this.setState({
            title: '',
            content: '',
            category: '',
            full_text: RichTextEditor.createEmptyValue(),
            images: [],
            page: 0
        })
    }

    submitForm = (e) => {
        console.log('submit')
        e.preventDefault()
        const details = {
            page: this.state.page,
            content: this.state.content,
            full_text: this.state.full_text.toString('html'),
            title: this.state.title,
            images: this.state.images[0],
        }

        if(details.full_text.length > 11) {
            this.props.addFormDetails(details)
            this.resetFields()
        } else {
            alert("Morate unijeti tekst za vijest")
        }
    }

    onDrop = (images) => {
        this.setState({
            // images: this.state.images.concat(images)
            images: images
        })
    }

    onDroptitle = (images) => {
        this.setState({
            // images: this.state.images.concat(images)
            imgTitle: images
        })
    }

    render() {

        const options = [
            { value: '1', label: 'Organi komore' },
            { value: '2', label: 'Slobodan pristun informacijama' },
          ]

        return (
            <div>
                <Header />
                <div className="col-md-2">
                    <Sidebar />
                </div>

                <div className="col-md-10 mainContent">
                    <div className="row box addMemberMargin addNews">
                        <form name="addForm" onSubmit={this.submitForm}>
                            <div className="col-md-4">
                                <div className="box-header with-border">
                                    <h3 className="box-title">Dodaj forma</h3>
                                </div>
                                <div className="box-body">
                                   <div className="form-group">
                                        <label>Kategorija</label>
                                        <Select 
                                            options={options}
                                            required
                                            onChange={(e) => this.changePage(e.value)}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Naslov</label>
                                        <input type="text" className="form-control" placeholder="Unesite ime i prezime" value={this.state.title} onChange={this.changeTitle} required/>
                                    </div>

                                    <div className="form-group">
                                        <label>Sadržaj</label>
                                        <input type="text" className="form-control" placeholder="Unesite kratak sadržaj" value={this.state.content} onChange={this.changeContent} required/>
                                    </div>
                                </div>

                                <div className="box-footer">
                                    <button type="submit" className="btn btn-primary">Sačuvaj</button>
                                </div>
                            </div>
                            <div className="col-md-8 textEditorPadding">
                                {/* <textarea className="textAreaNews" value={this.state.full_text} onChange={this.changeFullText} ></textarea> */}
                                <RichTextEditor  value={this.state.full_text} onChange={this.changeFullText} className="textEditor" required/>
                            </div>
                            <div className="col-md-4"></div>
                            <div className="col-md-8 textEditorPadding">
                                <ImageUploader
                                    withPreview={this.state.images !== undefined ? (this.state.images.length > 0 ? true : false): false}
                                    withIcon={true}
                                    buttonText='Choose images'
                                    onChange={this.onDrop}
                                    imgExtension={['.jpg', '.gif', '.png', '.pdf', 'docx']}
                                    maxFileSize={5242880}
                                    singleImage={true}
                                    label='Odaberite file za upload'
                                    value={this.state.images}
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </div>


        )
    }
}

export default withRouter(AddForm)