export const congressConstants = {
    GETCONGRESSPARTICIPANS_REQUEST: 'GET_CONGRESS_PARTICIPANS_REQUEST',
    GETCONGRESSPARTICIPANS_SUCCESS: 'GET_CONGRESS_PARTICIPANS_SUCCESS',
    GETCONGRESSPARTICIPANS_FAILURE: 'GET_CONGRESS_PARTICIPANS_FAILURE',

    PAYMENT_REQUEST: "PAYMENT_REQUEST",
    PAYMENT_SUCCESS: "PAYMENT_SUCCESS",
    PAYMENT_FAILURE: "PAYMENT_FAILURE",

    DELETE_PARTICIPANS_REQUEST: "DELETE_PARTICIPANS_REQUEST",
    DELETE_PARTICIPANS_SUCCESS: "DELETE_PARTICIPANS_SUCCESS",
    DELETE_PARTICIPANS_FAILURE: "DELETE_PARTICIPANS_FAILURE"
}