import axios from 'axios'

// var apiUrl = window.location.href.indexOf('admin.stomkomcg.me') >= 0 ? 'https://laravel.stomkomcg.me' : 'http://api.zk.test';
// var apiUrl = 'http://api.zk.test';
var apiUrl = 'https://laravel.stomkomcg.me';
var config = {
    headers: {'Authorization': "bearer " + localStorage.getItem('token')}
};

export function about_api() {
    return axios.get(apiUrl + '/about')
}

export function editAbout_api(content) {
    return axios.put(apiUrl + '/about/'+content.id, content, config);    
}

//
//novi api-s za organe komore i pristum slobodnim info
//
//get bodies of chember news
export function bodiesOfChember_api() {
    return axios.get(apiUrl + '/bodiesChember')
}
export function deleteBodiesChember_api(id) {
    return axios.delete(apiUrl + '/bodiesChember/'+id, config)
}
export function freeInfo_api() {
    return axios.get(apiUrl + '/freeInfo')
}
//add items
export function addForm_api(item) {
    const data = new FormData()
    data.append('page', item.page)
    data.append('content', item.content)
    data.append('full_text', item.full_text)
    data.append('title', item.title)
    data.append('images', item.images)

    console.log('api')

    return axios.post(apiUrl + '/bodiesChember', data, {
        headers: {
            "Access-Control-Allow-Origin": "*",
            // 'Authorization': "bearer " + localStorage.getItem('token')
        }
    })
}
