import { connect } from 'react-redux'
import { deleteBodiesChember_request, getBodiesChember_request } from '../../actions/aboute.action';
import BodiesOfChember from '../../components/aboutChember/BodiesOfChember';

const mapStateToProps = state => ({
    bodiesChember: state.aboutReducer.bodiesChember
})

const mapDispatchToProps = dispatch => ({
    getBodiesChember: () => dispatch(getBodiesChember_request()),
    deleteBodiesChember: (novelty) => dispatch(deleteBodiesChember_request(novelty))

})

const BodiesOfChemberCnt = connect (
    mapStateToProps,
    mapDispatchToProps
)(BodiesOfChember)

export default BodiesOfChemberCnt