export const userPath = {
    homePage: '/pocetna',
    contact: '/kontakt',
    membersList: '/spisakClanova',
    addMember: '/dodajClana',
    editMember: '/izmjeniClana',
    boardMembers: '/clanoviOdbora',
    biography: '/biografija',
    news: '/vijesti',
    newsForCategory: '/vijestiKategorije',
    editNovelty: '/izmijeniVijest',
    addNovelty: '/dodajVijest',
    parliamentPG:'/clanoviSkupstinePg',
    parliamentNk:'/clanoviSkupstineNk',
    parliamentCt:'/clanoviSkupstineCt',
    parliamentSouth:'/clanoviSkupstineJug',
    parliamentNorth:'/clanoviSkupstineSjever',
    advertisments: '/oglasi',
    advertismentAdd: '/dodajOglas',
    advertismentEdit: '/izmjeniOglas',
    about: '/onama',
    login: '/login',
    congress: '/kongres',
    bodiesOfChember: '/organi_komore',
    freeInfo: '/pristup_informacijama',
    addForm: '/dodaj_o_komori',
}